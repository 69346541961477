import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.scss";

function LanguageSwitcher({ placeholder, position }) {
  const { t, i18n } = useTranslation("global");
  const [showOptions, setShowOptions] = useState(false);
  const languageSelectorRef = useRef(null);
  const languages = [
    { code: "en", label: "English" },
    { code: "es", label: "Español" },
    { code: "pt", label: "Português" },
  ];
  const languageLabels = {
    en: "English",
    es: "Español",
    pt: "Português",
  };
  const handleLanguageClick = () => {
    setShowOptions(!showOptions);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setShowOptions(false);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        languageSelectorRef.current &&
        !languageSelectorRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="language-switcher" ref={languageSelectorRef}>
      {placeholder && <span>{t("main.headers.DefaultLanguage")}</span>}
      <div className="language-switcher__container">
        <div
          className="language-switcher__selected"
          onClick={handleLanguageClick}
        >
          {languageLabels[t("main.headers.language")]}
        </div>
        {showOptions && (
          <ul
            className={`language-switcher__options ${
              position ? "language-switcher__top" : null
            }`}
          >
            {languages.map(({ code, label }) => (
              <li key={code} onClick={() => changeLanguage(code)}>
                {label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default LanguageSwitcher;
