import { Helmet } from "react-helmet";

const Head = ({ lang, title, metaDescription, metaKeywords }) => {
  return (
    <div>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
    </div>
  );
};

export default Head;
