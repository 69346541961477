import React, { useState } from "react";

import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
export default function CountDown({ value, title }) {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div name="statistics">
        <div className="annual-statistic__container__col">
          <div className="annual-statistic__counter-index">
            <span className="annual-statistic__value">
              {counterOn && (
                <CountUp start={0} end={value} duration={2} delay={0} />
              )}
            </span>

            <h3 className="annual-statistic__name">{title}</h3>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}
