import React from "react";
import "./OurProducts.scss";
import ProductsDetails from "../../../components/common/ProductsDetails/ProductsDetails";
import freightage from "../../../img/freightage1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const OurProducts = ({ t }) => {
  return (
    <section className="our-products" id="features">
      <div className="our-products__svg">
        <svg
          id="sw-js-blob-svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          {" "}
          <defs>
            {" "}
            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
              {" "}
              <stop
                id="stop1"
                stopColor="rgba(255, 255, 255, 1)"
                offset="0%"
              ></stop>{" "}
              <stop id="stop2" stopColor="#2784b5" offset="100%"></stop>{" "}
            </linearGradient>{" "}
          </defs>{" "}
          <path
            fill="url(#sw-gradient)"
            d="M12.4,-27.5C13.9,-20.6,11.3,-12.8,15.2,-8.1C19.1,-3.4,29.3,-1.7,32.5,1.8C35.7,5.4,31.8,10.7,27.5,14.7C23.2,18.7,18.5,21.2,13.8,23.2C9.1,25.1,4.6,26.4,-0.6,27.4C-5.7,28.4,-11.4,29,-12.6,25.1C-13.9,21.2,-10.7,12.7,-15.6,7.8C-20.5,2.9,-33.6,1.4,-35.2,-0.9C-36.7,-3.2,-26.7,-6.4,-19.3,-7C-11.8,-7.7,-7,-5.8,-4.3,-11.9C-1.5,-18.1,-0.7,-32.4,2.4,-36.5C5.5,-40.5,10.9,-34.4,12.4,-27.5Z"
            width="100%"
            height="100%"
            transform="translate(50 50)"
            strokeWidth="0"
          ></path>{" "}
        </svg>
      </div>
      <div className="our-products__header">
        <h2 className="our-products__title">{t("main.OurProducts.h2")}</h2>
        <h3 className="our-products__h3">{t("main.OurProducts.h3")}</h3>

        <div className="our-products__wrapper">
          <p className="our-products__p">{t("main.OurProducts.p")}</p>
          <div className="our-products__image-container">
            <img
              className="our-products__image"
              src={freightage}
              alt="Freightage"
            />
            <div className="our-products__shadow-image"></div>
          </div>
        </div>
      </div>
      <ProductsDetails />
    </section>
  );
};
export default OurProducts;
