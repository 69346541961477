import React from "react";
import { HashLink } from "react-router-hash-link";
import { FaCheck } from "react-icons/fa";
import "./ProductCard.scss";
const ProductCard = ({ imgSrc, titleKey, feature1Key, feature2Key }) => {
  return (
    <article className="slider-container__card">
      <HashLink smooth to="/#home">
        <div className="slider-container__card-image-wrapper">
          <img
            className="slider-container__card-image"
            alt={titleKey}
            src={imgSrc}
          />
        </div>
        <div className="slider-container__card-content">
          <h3 className="slider-container__card-h3">{titleKey}</h3>
          <ul className="slider-container__card-paragraph-ul">
            <div className="slider-container__flexbox">
              <span>
                <FaCheck className="slider-container__checkmark" />
              </span>
              <li>{feature1Key}</li>
            </div>
            <div className="slider-container__flexbox">
              <span>
                <FaCheck className="slider-container__checkmark" />
              </span>
              <li>{feature2Key}</li>
            </div>
          </ul>
        </div>
        <div class="slider-container__circle"></div>
        <div class="slider-container__read-more">Read More &rarr;</div>
      </HashLink>
    </article>
  );
};

export default ProductCard;
