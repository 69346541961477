import React from "react";
import "./Hero.scss";
import { ButtonNeox1 } from "../../../components/utils/Button/Button";
import { Link } from "react-router-dom";
import image1 from "../../../img/gallery/image1.jpg";
import image8 from "../../../img/gallery/image8.jpg";
import image3 from "../../../img/gallery/image3.jpg";
import image13 from "../../../img/gallery/image13.jpeg";
import image5 from "../../../img/gallery/image5.jpg";
import image10 from "../../../img/gallery/image10.jpg";
const Hero = ({ t }) => {
  return (
    <>
      <section className="hero" id="home">
        <div className="hero__svg">
          <svg
            id="sw-js-blob-svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            {" "}
            <defs>
              {" "}
              <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                {" "}
                <stop
                  id="stop1"
                  stopColor="rgba(255, 255, 255, 1)"
                  offset="0%"
                ></stop>{" "}
                <stop id="stop2" stopColor="#008fe4" offset="100%"></stop>{" "}
              </linearGradient>{" "}
            </defs>{" "}
            <path
              fill="url(#sw-gradient)"
              d="M14.4,-30.4C16.1,-24.1,13,-14.9,10.9,-9.4C8.8,-3.8,7.7,-1.9,8,0.2C8.3,2.3,10,4.5,12,9.7C13.9,15,16,23.1,14,23.8C12,24.4,6,17.6,-0.4,18.3C-6.8,19,-13.7,27.3,-19.2,28.6C-24.6,30,-28.8,24.5,-26,18.6C-23.3,12.7,-13.6,6.3,-14.9,-0.7C-16.1,-7.8,-28.2,-15.5,-28.2,-16.7C-28.2,-17.8,-16,-12.2,-9.3,-15.6C-2.6,-19,-1.3,-31.2,2.6,-35.7C6.4,-40.1,12.8,-36.7,14.4,-30.4Z"
              width="100%"
              height="100%"
              transform="translate(50 50)"
              strokeWidth="0"
              // style="transition: all 0.3s ease 0s;"
            ></path>{" "}
          </svg>
        </div>
        <div className="hero__main">
          <div className="hero__content">
            <h1 className="hero__title">{t("main.hero.title")}</h1>
            <p className="hero__description">{t("main.hero.description")}</p>
            <Link to="/">
              <ButtonNeox1 title={t("main.hero.buttonText")} />
            </Link>
          </div>
          {/* BOX */}
          <div className="hero__box">
            <div class="box-container">
              <div class="wrapper">
                <div class="box-area">
                  <div class="box box1">
                    <img className="box-image" src={image1} alt="Imagen 1" />
                  </div>
                  <div class="box box2">
                    <img className="box-image" src={image8} alt="Imagen 1" />
                  </div>
                  <div class="box box3">
                    <img className="box-image" src={image3} alt="Imagen 1" />
                  </div>
                  <div class="box box4">
                    <img className="box-image" src={image13} alt="Imagen 1" />
                  </div>
                  <div class="box box5">
                    <img className="box-image" src={image5} alt="Imagen 1" />
                  </div>
                  <div class="box box6">
                    <img className="box-image" src={image10} alt="Imagen 1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Hero;
