import React from "react";
import "./PlanBusiness.scss";
import image1 from "../../../img/ilust.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PlanBusiness = ({ t }) => {
  return (
    <section className="plan-business s-about" id="about">
      <h2 className="s-about__title">{t("main.planBusiness.h2")}</h2>
      <div className="s-about__container">
        <div className="s-about__col s-about__col-1">
          <h3 className="s-about__h3">{t("main.planBusiness.h3-1")}</h3>
          <p className="s-about__paragraph">{t("main.planBusiness.p-1")}</p>
          <h3 className="s-about__h3">{t("main.planBusiness.h3-2")}</h3>
          <p className="s-about__paragraph">{t("main.planBusiness.p-2")}</p>
          <p className="s-about__link">
            <Link to="about-us" className="btn-text">
              {t("main.planBusiness.link")} &rarr;
            </Link>
          </p>
        </div>
        <div className="s-about__col s-about__col-2">
          <div className="s-about__image-container">
            <LazyLoadImage
              src={image1}
              alt="Represents our business vision"
              className="s-about__image image1"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanBusiness;
