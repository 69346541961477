import React from "react";
import logo from "../../../img/koshegio-logo-2.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../utils/LanguageSwitcher/LanguageSwitcher";
import SocialMediaIcons from "../../utils/SocialMediaIcons/SocialMediaIcons";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { ImCompass } from "react-icons/im";
import("./Footer.scss");
const Footer = () => {
  const { t } = useTranslation("global");
  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();

  return (
    <footer className="footer">
      <div className="footer__logo-container">
        <div className="footer__logo">
          <Link to="/">
            <img className="footer__logo-image" src={logo} alt="logo" />
          </Link>
        </div>
      </div>
      {/*  */}

      <div className="footer-blog-1">
        <div className="footer-blog-1__column">
          <h3 className="footer-blog-1__title">QUICK LINKS</h3>
          <ul className="footer-blog-1__list">
            <li>
              <a href="/">
                <ImCompass /> OUR STORY
              </a>
            </li>
            <li>
              <a href="/">
                <ImCompass /> FEATURES
              </a>
            </li>
            <li>
              <a href="/">
                <ImCompass /> PROJECTS DETAIL
              </a>
            </li>
            <li>
              <a href="/">
                <ImCompass /> SUPPORT
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-blog-1__column">
          <h3 className="footer-blog-1__title">ADDRESS LOCATION</h3>
          <p>
            123 Main Street, Anytown, USA
            <br />
            Zip: 12345
          </p>
        </div>

        <div className="footer-blog-1__column">
          <h3 className="footer-blog-1__title">CONTACT INFORMATION</h3>
          <ul className="footer-blog-1__list">
            <li>
              <FaPhone /> +1 (347) 927-396
            </li>
            <li>
              <FaEnvelope /> support@example.com
            </li>
          </ul>
        </div>

        <div className="footer-blog-1__column">
          <h3 className="footer-blog-1__title">ABOUT COMPANY</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            vitae nisi efficitur, fermentum metus at, finibus libero.
          </p>
        </div>
      </div>

      {/*  */}

      <div className="footer__footer-container">
        <div className="footer__col footer__col1">
          <p>© {anioActual} Koshegio-Themes. All Rights Reserved.</p>
        </div>

        <div className="footer__col footer__col2">
          <div className="footer__social-media">
            <SocialMediaIcons size="32" />
          </div>
          <ul className="footer__link-container">
            <li className="footer__link">
              <Link to="/">{t("main.navBar.contactUs")}</Link>
            </li>
            <span>|</span>
            <li className="footer__link">
              <Link to="/">{t("main.navBar.aboutUs")}</Link>
            </li>
            <span>|</span>
            <li className="footer__link">
              <Link to="/">{t("main.navBar.PrivacyPolicy")}</Link>
            </li>
          </ul>
        </div>
        <div className="footer__col footer__col3">
          <LanguageSwitcher placeholder="placeholder" position="top" />
        </div>
      </div>
      <div className="footer__sub-footer">
        <p className="footer__sub-footer-p">
          123 Main Street, Springfield, IL 62704
        </p>
        <p className="footer__sub-footer-p">
          TerraFirm Enterprises. - EIN: 99-9999999
        </p>
      </div>
    </footer>
  );
};
export default Footer;
