import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/common/Navbar/Navbar";
import Footer from "./components/common/Footer/Footer";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import InternationalizationProvider from "./components/utils/InternationalizationProvider";
import Error404 from "./pages/404/Error404";
import TopBar from "./components/common/TopBar/Topbar";
import Home from "./pages/home/Home";
function App() {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <InternationalizationProvider>
          <TopBar />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
          <Footer />
        </InternationalizationProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
