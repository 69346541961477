import image1 from "../../../img/gallery/image1.jpg";
import image2 from "../../../img/gallery/image2.jpg";
import image3 from "../../../img/gallery/image3.jpg";
import image4 from "../../../img/gallery/image4.webp";
import image5 from "../../../img/gallery/image5.jpg";
import image6 from "../../../img/gallery/image6.webp";
import image7 from "../../../img/gallery/image7.jpg";
import image8 from "../../../img/gallery/image8.jpg";
import image9 from "../../../img/gallery/image9.jpg";
import image10 from "../../../img/gallery/image10.jpg";
import image11 from "../../../img/gallery/image11.jpg";
import image12 from "../../../img/gallery/image12.jpg";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import("./Gallery.scss");
const Gallery = () => {
  return (
    <div className="gallery">
      <div className="gallery-container">
        <figure className="gallery-image-item gallery-image-item-1 ">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image1}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-2">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image2}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-3">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image3}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-4">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image4}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-5">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image5}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-6">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image6}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-7">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image7}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-8">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image8}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-9">
          <LazyLoadImage
            className="gallery-image "
            alt="Gallery Ilustatrive"
            src={image9}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-10">
          <LazyLoadImage
            className="gallery-image 0"
            alt="Gallery Ilustatrive"
            src={image10}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-11">
          <LazyLoadImage
            className="gallery-image 1"
            alt="Gallery Ilustatrive"
            src={image11}
          />
        </figure>
        <figure className="gallery-image-item gallery-image-item-12">
          <LazyLoadImage
            className="gallery-image 2"
            alt="Gallery Ilustatrive"
            src={image12}
          />
        </figure>
      </div>
    </div>
  );
};
export default Gallery;
