import React, { useEffect } from "react";
import "./FAQ.scss";
import FAQAccordion from "../../../components/common/Accordion/Accordion";

const FAQ = ({ t }) => {
  useEffect(() => {
    const parentElements = document.querySelectorAll(".animate-text");
    parentElements.forEach((parentElement) => {
      const firstChildWidth = parentElement.children[0].clientWidth;
      parentElement.style.width = `${firstChildWidth}px`;
    });
  }, []); // Empty dependency array ensures the effect runs only once after mounting

  return (
    <section className="faq-container" id="faq">
      <div className="faq-container__column faq-container__column-1">
        <div className="faq-container__wrapper">
          <div className="faq-container__bg-text-container">
            <div className="faq-container__animate-text">
              <span className="faq-container__animated-text">
                Asked Questions
              </span>
              <span className="faq-container__animated-text">
                Asked Questions
              </span>
            </div>
            <div className="faq-container__animate-text left-text">
              <span className="faq-container__animated-text">
                Asked Questions
              </span>
              <span className="faq-container__animated-text">
                Asked Questions
              </span>
            </div>
            <div className="faq-container__animate-text ">
              <span className="faq-container__animated-text">
                Asked Questions
              </span>
              <span className="faq-container__animated-text">
                Asked Questions
              </span>
            </div>
          </div>
          <div className="faq-container__main-wrapper">
            <div className="faq-container__main">
              <h3>{t("main.hero.title")}</h3>
              <p>{t("main.hero.description")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="faq-container__column faq-container__column-2">
        <FAQAccordion t={t} />
      </div>
    </section>
  );
};

export default FAQ;
