import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import "./TopBar.scss";
import SocialMediaIcons from "../../utils/SocialMediaIcons/SocialMediaIcons";
import LanguageSwitcher from "../../utils/LanguageSwitcher/LanguageSwitcher";

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="top-bar__row">
        <div className="top-bar__contact-info">
          <span className="top-bar__contact-info-item">
            <i className="icon">
              <FaEnvelope />
            </i>{" "}
            email@example.com
          </span>
          <span className="top-bar__contact-info-item">
            <i className="icon">
              <FaPhone />
            </i>{" "}
            +123 456 789
          </span>
        </div>
        <div className="top-bar__right-element">
          <SocialMediaIcons size="22" />
          <div className="top-bar__separator"> | </div>
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
