import React from "react";
import imageParallax from "../../../img/importation.jpg";
import { Parallax } from "react-parallax";
import { Button2 } from "../../../components/utils/Button/Button";
import { Link } from "react-router-dom";
import("./GetInContact.scss");
const GetInContact = ({ t }) => {
  return (
    <section className="get-in-contact parallax-section" id="features">
      <Parallax strength={600} bgImage={imageParallax}>
        <div className="content-parallax">
          <h3 className="content-parallax__title">
            {t("main.GetInContact.h3")}
          </h3>
          <Link to="/contact">
            <Button2>{t("main.GetInContact.button")}</Button2>
          </Link>
        </div>
      </Parallax>
    </section>
  );
};
export default GetInContact;
