import React, { useState } from "react";
import FeatureCard from "../../../components/common/FeatureCard/FeatureCard";
import { IconsMap } from "../../../components/utils/IconsMap";
import "./Services.scss";
import defaultContent from "../../../content/En/translation.json";

const Services = ({ t }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (e) => {
    let x = e.clientX;
    let y = e.clientY;
    setMousePosition({
      x,
      y,
    });
  };

  return (
    <section className="services-section" id="services">
      <h2 className="services-section__title">
        {t(`main.featuredSection.title`)}
      </h2>
      <div className="services-section__container">
        <div
          className="services-section__card-container "
          onMouseMove={handleMouseMove}
        >
          {defaultContent.main.featuredSection.featuredSectionContent.map(
            (card, index) => {
              return (
                <FeatureCard
                  key={index}
                  index={index}
                  icon={
                    <IconsMap
                      name={t(
                        `main.featuredSection.featuredSectionContent.${index}.icon`
                      )}
                    />
                  }
                  title={t(
                    `main.featuredSection.featuredSectionContent.${index}.title`
                  )}
                  description={t(
                    `main.featuredSection.featuredSectionContent.${index}.description`
                  )}
                  mousePosition={mousePosition}
                />
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};
export default Services;
