import React, { useEffect, useState } from "react";

import "./Navbar.scss";
import { FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import logo from "../../../img/koshegio-logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const { t } = useTranslation("global");

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        const scrollPosition = window.pageYOffset;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={`header ${isSticky ? "sticky" : ""}`}>
      <div className="nav-wrapper">
        <Link to="/">
          <div className="nav-logo-container">
            <img className="nav-logo-image" src={logo} alt="Logo" />
          </div>
        </Link>
        {!showNavbar && (
          <button
            className="nav-elements__btn"
            onClick={() => setShowNavbar(true)}
          >
            <FaBars />
          </button>
        )}
        <nav className="nav-elements">
          <div
            className={`nav-elements__container  ${
              showNavbar ? "responsive_nav" : "show-off"
            }`}
          >
            <ul className="nav-elements__ul">
              <li className="nav-elements__li">
                <HashLink
                  smooth
                  to="/#home"
                  onClick={() => setShowNavbar(false)}
                  className={`nav-elements__link ${
                    activeSection === "home" ? "active" : ""
                  }`}
                >
                  {t("main.navBar.home")}
                </HashLink>
              </li>
              <li className="nav-elements__li">
                <HashLink
                  smooth
                  to="/#features"
                  onClick={() => setShowNavbar(false)}
                  className={`nav-elements__link ${
                    activeSection === "features" ? "active" : ""
                  }`}
                >
                  {t("main.navBar.features")}
                </HashLink>
              </li>
              <li className="nav-elements__li">
                <HashLink
                  smooth
                  to="/#about"
                  onClick={() => setShowNavbar(false)}
                  className={`nav-elements__link ${
                    activeSection === "about" ? "active" : ""
                  }`}
                >
                  {t("main.navBar.aboutUs")}
                </HashLink>
              </li>
              <li className="nav-elements__li">
                <HashLink
                  smooth
                  to="/#services"
                  onClick={() => setShowNavbar(false)}
                  className={`nav-elements__link ${
                    activeSection === "services" ? "active" : ""
                  }`}
                >
                  {t("main.navBar.services")}
                </HashLink>
              </li>
              <li className="nav-elements__li">
                <HashLink
                  smooth
                  to="/#faq"
                  onClick={() => setShowNavbar(false)}
                  className={`nav-elements__link ${
                    activeSection === "faq" ? "active" : ""
                  }`}
                >
                  {t("main.navBar.faq")}
                </HashLink>
              </li>
            </ul>
            <button
              className="nav-elements__btn nav-elements__btn-close"
              onClick={() => setShowNavbar(false)}
            >
              <FaTimes />
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Navbar;
