import React, { useEffect, useRef, useState } from "react";
import "./FeatureCard.scss";
const FeatureCard = ({ index, icon, title, description, mousePosition }) => {
  const [rect, setRect] = useState(0);
  const ref = useRef();

  useEffect(() => {
    setRect((prev) => ({
      ...prev,
      x: ref.current.getBoundingClientRect().x,
      y: ref.current.getBoundingClientRect().y,
    }));
  }, [mousePosition]);

  return (
    <div className="feature-card" ref={ref}>
      <div
        className="feature-card-border"
        style={{
          background: `radial-gradient(600px circle at ${
            mousePosition.x - rect.x
          }px ${
            mousePosition.y - rect.y
          }px, rgba(255, 255, 255,0.4), transparent 40%)
           
          `,
          zIndex: 1,
          transition: "opacity 500ms",
        }}
      ></div>
      <div
        className="feature-card-interne"
        style={{
          background: `radial-gradient(800px circle at ${
            mousePosition.x - rect.x
          }px ${
            mousePosition.y - rect.y
          }px, rgba(255, 255, 255,0.4), transparent 40%)`,
          zIndex: 3,
          transition: "opacity 500ms",
        }}
      ></div>
      <div className="card-content">
        <div className="feature-icon">{icon}</div>
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  );
};
export default FeatureCard;
