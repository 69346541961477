import React from "react";
import Hero from "./Hero/Hero";
import GetInContact from "./GetInContact/GetInContact";
import OurProducts from "./OurProducts/OurProducts";
import Gallery from "../../components/common/Gallery/Gallery";
import PlanBusiness from "./PlanBusiness/PlanBusiness";
import { useTranslation } from "react-i18next";
import Head from "../../components/utils/Head/Head";
import ScrollToTopButton from "../../components/utils/ScrollToTopButton/ScrollToTopButton";
import FAQ from "./FAQ/FAQ";
import Services from "./Services/Services";
import AnnualStatistcs from "./AnnualStatistics/AnnualStatistcs";
const Home = () => {
  const { t } = useTranslation("global");
  return (
    <>
      {/* This <Head> component utilizes the Helmet library to manage the document head.
     You have the flexibility to customize metadata for each page, ensuring unique
     titles, descriptions, and keywords as needed. */}
      <Head
        lang={t("main.headers.language")}
        title={t("main.hero.title")}
        metaDescription={t("main.hero.description")}
        metaKeywords={t("main.headers.keywords")}
      />
      <ScrollToTopButton />
      <Hero t={t} />
      <OurProducts t={t} />
      <GetInContact t={t} />
      <PlanBusiness t={t} />
      <AnnualStatistcs t={t} />
      <Services t={t} />
      <FAQ t={t} />
      <Gallery />
    </>
  );
};
export default Home;
