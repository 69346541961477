import React from "react";
import { GoMirror } from "react-icons/go";
import { MdWorkspacePremium } from "react-icons/md";
import { GiEarthAmerica } from "react-icons/gi";
import { GrUserWorker } from "react-icons/gr";
import { FaHandHolding, FaBoxOpen } from "react-icons/fa";

const iconMap = {
  GoMirror: <GoMirror />,
  GiEarthAmerica: <GiEarthAmerica />,
  FaBoxOpen: <FaBoxOpen />,
  MdWorkspacePremium: <MdWorkspacePremium />,
  FaHandHolding: <FaHandHolding />,
  GrUserWorker: <GrUserWorker />,
  // Add other icons as needed
};

export const IconsMap = ({ name }) => {
  const IconComponent = iconMap[name];
  return IconComponent;
};
