import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../../img/OurProduct/img1.jpg";
import img2 from "../../../img/OurProduct/img2.jpg";
import img3 from "../../../img/OurProduct/img3.jpg";
import img4 from "../../../img/OurProduct/img4.jpg";
import img5 from "../../../img/OurProduct/img5.jpg";
import img6 from "../../../img/OurProduct/img6.jpg";

import Slider from "react-slick";

import "./ProductsDetails.scss";
import ProductCard from "../ProductCard/ProductCard";
const ProductsDetails = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1235,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { t } = useTranslation("global");
  return (
    <div className="card-container">
      <div className="slider-container">
        <Slider {...settings}>
          {/* Article 1 */}
          <ProductCard
            imgSrc={img1}
            titleKey={t("main.OurProductsProductsDetails.ProductDetail1.title")}
            feature1Key={t(
              "main.OurProductsProductsDetails.ProductDetail1.feature1"
            )}
            feature2Key={t(
              "main.OurProductsProductsDetails.ProductDetail1.feature2"
            )}
          />
          {/* Article 2 */}
          <ProductCard
            imgSrc={img2}
            titleKey={t("main.OurProductsProductsDetails.ProductDetail2.title")}
            feature1Key={t(
              "main.OurProductsProductsDetails.ProductDetail2.feature1"
            )}
            feature2Key={t(
              "main.OurProductsProductsDetails.ProductDetail2.feature2"
            )}
          />
          {/* Article 3 */}
          <ProductCard
            imgSrc={img3}
            titleKey={t("main.OurProductsProductsDetails.ProductDetail3.title")}
            feature1Key={t(
              "main.OurProductsProductsDetails.ProductDetail3.feature1"
            )}
            feature2Key={t(
              "main.OurProductsProductsDetails.ProductDetail3.feature2"
            )}
          />
          {/* Article 4 */}
          <ProductCard
            imgSrc={img4}
            titleKey={t("main.OurProductsProductsDetails.ProductDetail4.title")}
            feature1Key={t(
              "main.OurProductsProductsDetails.ProductDetail4.feature1"
            )}
            feature2Key={t(
              "main.OurProductsProductsDetails.ProductDetail4.feature2"
            )}
          />
          {/* Article 5 */}
          <ProductCard
            imgSrc={img6}
            titleKey={t("main.OurProductsProductsDetails.ProductDetail5.title")}
            feature1Key={t(
              "main.OurProductsProductsDetails.ProductDetail5.feature1"
            )}
            feature2Key={t(
              "main.OurProductsProductsDetails.ProductDetail5.feature2"
            )}
          />
          {/* Article 6 */}
          <ProductCard
            imgSrc={img5}
            titleKey={t("main.OurProductsProductsDetails.ProductDetail6.title")}
            feature1Key={t(
              "main.OurProductsProductsDetails.ProductDetail6.feature1"
            )}
            feature2Key={t(
              "main.OurProductsProductsDetails.ProductDetail6.feature2"
            )}
          />
        </Slider>
      </div>
    </div>
  );
};
export default ProductsDetails;
